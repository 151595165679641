import React, { useState, useEffect } from "react"
import Dropdown from "../Dropdown/Dropdown"
import { marketOptions } from "../CompanyUserInfo/options/marketOptions"
import styles from "./index.module.scss"

const firstOption = {
  id: "All",
  value: "All",
}
export const MarketSelectorView = ({ market, searchType, onChange }) => {
  const [regionValue, setRegionValue] = useState({})
  const [subRegionValue, setSubRegionValue] = useState(firstOption)
  useEffect(() => {
    if (market) {
      const region =
        marketOptions.find(
          (option) => option.databaseName === market.substring(0, 2),
        ) ?? {}
      const subRegion =
        region.subValue.find(
          (option) => option.databaseName === market.substring(2, 4),
        ) ?? firstOption
      setRegionValue(region)
      setSubRegionValue(
        subRegion.value ? subRegion : { ...subRegion, value: subRegion.name },
      )
    } else {
      setRegionValue({})
      setSubRegionValue(firstOption)
    }
  }, [market])

  const dropdownTitle = searchType === 'professional' ? 'Location' : 'Market';
  return (
    <div className={styles.root}>
      <p className={styles.label}>{dropdownTitle}</p>
      <Dropdown
        name="market_region"
        placeholder="Select Region"
        fieldStyle="black"
        className={styles.dropdown}
        options={marketOptions}
        value={regionValue}
        onChange={(_, value) => {
          setRegionValue(value)
          setSubRegionValue(firstOption)
          onChange(value.databaseName)
        }}
      />
      <Dropdown
        name="market_subregion"
        fieldStyle="black"
        className={styles.dropdown}
        disabled={!regionValue?.id}
        options={[
          firstOption,
          ...(regionValue?.subValue?.map((item) => ({
            ...item,
            value: item.name,
          })) ?? []),
        ]}
        value={subRegionValue}
        onChange={(_, value) => {
          setSubRegionValue(value)
          onChange(
            `${regionValue.databaseName}${
              value.id === firstOption.id ? "" : value.databaseName
            }`,
          )
        }}
      />
    </div>
  )
}
