import React, { useState } from "react"
import Button from "../Button/Button"
import Dropdown from "../Dropdown/Dropdown"
import TrueTag from "../elements/TrueTag"
import { marketOptions } from "../CompanyUserInfo/options/marketOptions"
import styles from "./index.module.scss"

const firstOption = {
  id: "All",
  value: "All",
}
export const TagFinderView = ({ tagList, userType, onUpdateTagList }) => {
  const [marketList, setMarketList] = useState(tagList)
  const [regionValue, setRegionValue] = useState({})
  const [subRegionValue, setSubRegionValue] = useState(firstOption)
  const [marketCandidate, setMarketCandidate] = useState("")
  const dropdownTitle = userType === "professional" ? "Choose Your Locations:" : "Choose Your Markets:"
  const addButtonTitle = userType === "professional" ? "Add Location" : "Add Market"
  return (
    <div className={styles.root}>
      <p className={styles.label}>{dropdownTitle}</p>
      <div className={styles.tagList}>
        {marketList.map((market) => (
          <TrueTag
            classes={styles.tag}
            title={(() => {
              const { value: region = "", subValue = [] } =
                marketOptions.find(
                  (option) => option.databaseName === market.substring(0, 2),
                ) ?? {}
              const { name: subRegion = "" } =
                subValue.find(
                  (option) => option.databaseName === market.substring(2, 4),
                ) ?? {}
              return subRegion ? `${subRegion}, ${region}` : region
            })()}
            onClick={() => {
              const nextMarketList = marketList.filter(
                (item) => item !== market,
              )
              setMarketList(nextMarketList)
              onUpdateTagList(nextMarketList)
            }}
          />
        ))}
      </div>
      <Dropdown
        name="market_region"
        placeholder="Select Region"
        fieldStyle="black"
        className={styles.dropdown}
        options={marketOptions}
        value={regionValue}
        onChange={(_, value) => {
          setRegionValue(value)
          setSubRegionValue(firstOption)
          setMarketCandidate(value.databaseName)
        }}
      />
      <Dropdown
        name="market_subregion"
        fieldStyle="black"
        className={styles.dropdown}
        disabled={!regionValue?.id}
        options={[
          firstOption,
          ...(regionValue?.subValue?.map((item) => ({
            ...item,
            value: item.name,
          })) ?? []),
        ]}
        value={subRegionValue}
        onChange={(_, value) => {
          setSubRegionValue(value)
          setMarketCandidate(
            `${regionValue.databaseName}${
              value.id === firstOption.id ? "" : value.databaseName
            }`,
          )
        }}
      />
      <Button
        className={styles.btn}
        buttonStyle="outlined"
        disabled={!marketCandidate || marketList.includes(marketCandidate)}
        onClick={() => {
          const nextMarketList = [...marketList, marketCandidate]
          setMarketList(nextMarketList)
          onUpdateTagList(nextMarketList)
        }}
      >
          {addButtonTitle}
      </Button>
    </div>
  )
}
