/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react"
import styles from "./UserInfo.module.scss"
import UserImage from "../UserImage/UserImage"

import Button from "../Button/Button"
import Input from "../Input/Input"
import Dropdown from "../Dropdown/Dropdown"
import { experienceOptions, options, professionOptions, jobTitleOptions, genderOptions, industryOptions, marketOptions } from "./helpers"
import { TagFinderView } from "../TagFinderView"
import { nameValidaton } from "./validation"
import { objectDeepCopy } from "../../helpers"

import UserInfoShowInfo from "./UserInfoShowInfo"
import ConfirmCancelPopup from "../Popup/ConfirmCancelPopup"

import ReportButton from "../ReportButton/ReportButton"
import { Prompt } from "react-router-dom"
import { messages } from "../../helpers/constants"

const UserInfo = ({
  image,
  openPhotoPopup,
  anotherUser,
  onOpenChat,
  fullName,
  onChangeNameAndRole,
  onDeletePhotoProfile,
  jobTitle,
  reported,
  onReport,
  gender,
  profession,
  experience,
  industry,
  markets,
  isBlocked,
  userRole,
  onBlock,
  userType
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [market, setMarket] = useState({ showedMarket: "", editedMarket: "" })
  const [name, setName] = useState({ showedName: "", editedName: "" })

  const [errors, setErrors] = useState({
    fullName: { valid: true, errorMessage: "" },
  })

  const [dropdownData, setDropdownData] = useState({
    activity: {
      showed: { value: "" },
      edited: { value: "" },
    },
    jobTitle: {
      showed: { value: "" },
      edited: { value: "" },
    },
    gender: {
      showed: { value: "" },
      edited: { value: "" },
    },
    profession: {
      showed: { value: "" },
      edited: { value: "" },
    },
    experience: {
      showed: { value: "" },
      edited: { value: "" },
    },
    industry: {
      showed: { value: "" },
      edited: { value: "" },
    },
  })

  useEffect(() => {
    const gendr = 
      (gender &&
        genderOptions.find((option) => option.databaseName === gender)) || ""
    const jobT = 
      (jobTitle &&
        jobTitleOptions.find((option) => option.databaseName === jobTitle)) || ""
    const prof = 
      (profession &&
        professionOptions.find((option) => option.databaseName === profession)) || ""
    const exp = 
      (experience &&
        experienceOptions.find((option) => option.databaseName === experience)) || ""
    const ind = 
      (industry &&
        industryOptions.find((option) => option.databaseName === industry)) || ""

    setDropdownData({
      gender: { showed: gendr, edited: gendr},
      jobTitle: { showed: jobT, edited: jobT},
      profession: { showed: prof, edited: prof },
      experience: { showed: exp, edited: exp },
      industry: { showed: ind, edited: ind }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender, jobTitle, profession, experience, industry])

  useEffect(() => {
    if (fullName) {
      setName({ showedName: fullName, editedName: fullName })
    }
  }, [fullName])

  useEffect(() => {
    if (markets) {
      setMarket({ showedMarket: markets, editedMarket: markets })
    }
  }, [markets])

  const checkToSave = () => {
    return Object.values(errors).some((item) => item.valid === false)
  }

  const handleCancel = () => {
    const data = objectDeepCopy(dropdownData)
    setIsEditing(false)
    setHasChanges(false)
    setIsPopupOpened(false)
    setDropdownData({
      ...data,
      gender: { ...data.gender, edited: data.gender.showed },
      jobTitle: { ...data.jobTitle, edited: data.jobTitle.showed },
      profession: { ...data.profession, edited: data.profession.showed },
      experience: { ...data.experience, edited: data.experience.showed },
      industry: { ...data.industry, edited: data.experience.showed}
    })
    setName({ ...name, editedName: name.showedName })
    setMarket({ ...market, editedMarket: market.showedMarket })
  }

  const onCancelClick = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setIsEditing(false)
    }
  }

  const handleSave = () => {
    const data = objectDeepCopy(dropdownData)
    setIsEditing(false)
    setHasChanges(false)
    setDropdownData({
      ...data,
      gender: { ...data.gender, showed: data.gender.edited },
      jobTitle: { ...data.jobTitle, showed: data.jobTitle.edited },
      profession: { ...data.profession, showed: data.profession.edited },
      experience: { ...data.experience, showed: data.experience.edited },
      industry: { ...data.industry, showed: data.industry.edited },
    })
    setName({ ...name, showedName: name.editedName })
    setMarket({ ...market, showedMarket: market.editedMarket })
    onChangeNameAndRole({
      ["full_name"]: name.editedName,
      ["gender"]: dropdownData.gender.edited.databaseName || "",
      ["job_title"]: dropdownData.jobTitle.edited.databaseName || "",
      ["profession"]: dropdownData.profession.edited.databaseName || "",
      ["experience"]: dropdownData.experience.edited.databaseName || "",
      ["industry"]: dropdownData.industry.edited.databaseName || "",
      ["markets"]: market.editedMarket
    })
  }
  const handleChangeName = (e) => {
    setHasChanges(true)
    setName({ ...name, editedName: e.target.value })
    setErrors({ ...errors, fullName: nameValidaton(e.target.value) })
  }

  const handleChangeMarket = (marketList) => {
    setHasChanges(true)
    setMarket({ ...market, editedMarket: marketList })
  }

  const handleChange = (name, value) => {
    setHasChanges(true)
    const data = objectDeepCopy(dropdownData)
    setDropdownData({
      ...data,
      [name]: {
        ...dropdownData[name],
        edited: value,
      },
    })
  }

  const handleDropdownChange = (name, value) => {
    setHasChanges(true)
    const descrChange = options.find((option) => option.value === value.value)
    const data = objectDeepCopy(dropdownData)
    setDropdownData({
      ...data,
      [name]: { ...dropdownData[name], edited: value },
      activity: {
        ...dropdownData.activity,
        edited: descrChange ? { value: "" } : { ...value },
      },
    })
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  return (
    <div className={styles.userInfoContainer}>
      <ReportButton
        className={styles.reportIcon}
        anotherUser={anotherUser}
        isBlocked={isBlocked}
        isReported={reported}
        onBlock={() => onBlock(true)}
        onReport={onReport}
        onUnblock={() => onBlock(false)}
        userRole={userRole}
      />
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      {isPopupOpened && hasChanges && (
        <ConfirmCancelPopup
          handleCancel={handleCancel}
          setIsPopupOpened={setIsPopupOpened}
        />
      )}
      <div className={styles.imageContainer}>
        <UserImage
          image={image}
          onUpdate={openPhotoPopup}
          selectedUser={anotherUser}
          onDelete={onDeletePhotoProfile}
        />
      </div>

      {isEditing ? (
        <div className={styles.userInfoInputs}>
          <Input
            fieldStyle="black"
            error={!errors.fullName.valid}
            errorMessage={errors.fullName.errorMessage}
            value={name.editedName}
            onChange={handleChangeName}
            label="Full Name*"
            className={styles.editItem}
            autoFocus
          />

          <Dropdown
            fieldStyle="black"
            name="jobTitle"
            options={jobTitleOptions}
            onBlur={() => {}}
            onChange={handleChange}
            value={dropdownData.jobTitle.edited}
            label="Title*"
            className={styles.editItem}
          />
          <Dropdown
            fieldStyle="black"
            name="profession"
            options={professionOptions}
            onBlur={() => {}}
            onChange={handleChange}
            value={dropdownData.profession.edited}
            label="Profession"
            className={styles.editItem}
          />

          <Dropdown
            name="experience"
            fieldStyle="black"
            options={experienceOptions}
            onBlur={() => {}}
            onChange={handleChange}
            value={dropdownData.experience.edited}
            label="Experience*"
            className={styles.editItem}
          />

          <Dropdown
            fieldStyle="black"
            name="gender"
            options={genderOptions}
            onBlur={() => {}}
            onChange={handleChange}
            value={dropdownData.gender.edited}
            label="Gender"
            className={styles.editItem}
          />

          <Dropdown
            fieldStyle="black"
            name="industry"
            options={industryOptions}
            onBlur={() => {}}
            onChange={handleChange}
            value={dropdownData.industry.edited}
            label="Industry"
            className={styles.editItem}
          />

          <TagFinderView
            tagList={market.showedMarket.split(",").filter((item) => Boolean(item))}
            userType={'professional'}
            onUpdateTagList={(marketList) => handleChangeMarket(marketList.join())}
          />

          <div className={styles.buttonsWrapper}>
            <Button
              className={styles.saveButtons}
              onClick={onCancelClick}
              buttonStyle="outlined"
            >
              Cancel
            </Button>
            <Button
              className={styles.saveButtons}
              onClick={handleSave}
              disabled={checkToSave()}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <UserInfoShowInfo
          name={name}
          title={jobTitle}
          anotherUser={anotherUser}
          userType={userType}
          style={styles}
          dropdownData={dropdownData}
          handleEdit={handleEdit}
          onOpenChat={onOpenChat}
        />
      )}
    </div>
  )
}

UserInfo.defaultProps = {
  fullName: "User Name",
}

export default UserInfo
